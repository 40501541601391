.reviews{
    padding: 100px;
    background: #e8f2fe;
    background-position: center;
    font-family: 'Nunito Sans';
    &__head{
        display: flex;
        &__left{
            flex: 1;
            &__heading{
                flex: 1;
                font-weight: 500;
                border-left: 5px solid #dfb24f;
                font-size: 40px;
                padding: 8px 0 8px 30px;
            }
            &__sub-heading{
                padding-top: 16px;
                color: rgb(76, 76, 76);
                font-size: 15px;
            }
        }
        &__right{
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
        padding-bottom: 50px;
    }
}
@media only screen and (max-width: 830px) {
    .reviews{
        padding: 32px;
        &__head{
            display: block;
            &__right{
                justify-content: flex-start;
                padding-top: 20px;
                height: 50px;
            }
        }
    }
}