.navbar-scrolled{
    position: fixed;
    z-index: 5;
    width: calc(100vw - 9rem);
    display: flex;
    align-items: center;
    color: #000;
    justify-content: space-between;
    padding: 0.2rem 5rem 0rem 4rem;
    background-color: #dfb24f;
    backdrop-filter: blur(5px);
    &__right__item{
        color: #000;
        margin-left: 2.5rem;
            padding: 5px;
            cursor: pointer;
            &:hover{
                border-bottom: 2px solid #000;
            }
            text-decoration: none;
            font-size: 18px;
            font-family: "Nunito Sans";
            font-weight: 800;
    }
    .nav-item-selected{
        border-bottom: 2px solid #000;
    }

}
.navbar{
    position: fixed;
    z-index: 5;
    width: calc(100vw - 9rem);
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: space-between;
    padding: 0.2rem 5rem 0rem 4rem;
    &__left{
        font-size: 32px;
        font-family: "Great Vibes";
        display: flex;
        align-items: center;
        cursor: pointer;

    }
    &__right{
        display: flex;
        align-items: center;
        &__item{
            margin-left: 2.5rem;
            padding: 5px;
            cursor: pointer;
            &:hover{
                border-bottom: 2px solid #ffffff;
            }
            color:#fff;
            text-decoration: none;
            font-size: 18px;
            font-family: "Nunito Sans";
            font-weight: 600;
        }
    }
    .nav-item-selected{
        border-bottom: 1.5px solid #fff;
    }
}

.sidebar-menu-icon{
    img {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%);
        z-index:5;
      } 
    display: none;
}
.sidebar-menu-icon-scrolled{
    display: none;
}
@media only screen and (min-width: 828px) {

    .sidebar-top{
        display: none;
    }
}
@media only screen and (max-width: 1200px) {
    .navbar{
        width: calc(100vw - 3rem);
        padding: 0.7rem 2rem 0.7rem 1rem;
    }
}

@media only screen and (max-width: 828px) {
    .navbar{
        width: calc(100vw - 3rem);
        padding: 0.7rem 2rem 0.7rem 1rem;
    }
    .navbar-scrolled{
        width: calc(100vw - 3rem);
        padding: 0.2rem 2rem 0rem 1rem;
        &__right__item{
            margin: 0 0 30px -50px;
        }
    }
    .navbar__right{
        display: none;
    }
    .sidebar-menu-icon {
        display: block;
        cursor: pointer;
    }
    .sidebar-menu-icon-scrolled{
        display: block;
        cursor: pointer;
    }
    .menu-toggle {
        .sidebar-top{
            display: block;
            position: absolute;
            left: 20px;
            top: 20px;
            .nav-item-selected{
                border-bottom: 2px solid #fff;
            }
        }
        .close-sidebar {
            box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
            padding: 4px 8px;
            border-radius: 6px;
            width: fit-content;
            cursor: pointer;
            margin-bottom: 40px;
        }
    }
    .menu-toggle{
        display: flex;
        flex-direction: column;
        position: absolute;
        top:0;
        height: calc(100vh - 3px);
        border-radius: 12px;
        background-color: #21294f;
        margin: 0 0 0px -24px;
        width: 380px;
        padding-left: 20px;
        z-index: 7;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        .navbar__right__item{
            color: #fff;
        }
        .navbar-scrolled__right__item{
            color: #fff;
        }
    }
    .navbar__right{
        padding-top: 20px;
        &__item{
            margin: 0 0 30px -50px;
        }
    }
    .sm-disp-none{
        display: none;
    }
    .contact-button{
        font-size: 12px;
    }
    sidebar-menu-icon-scrolled{
        img {
            filter:none;
          } 
        }
}