.services{
    background: linear-gradient(to right, rgba(65, 48, 12,0.2), rgba(65, 48, 12, 0.4)), url('../../assets/images/facility.jpeg') ;
    background-size: cover;
    background-position: center;
    padding: 60px;
    &__heading{
        font-family: "Nunito Sans", sans-serif;
        font-size: 45px;
        font-weight: 500;
        color: #fff;
        padding: 20px 20px 60px 20px;
        text-align: center;
        span{
            border-bottom: 1px solid #fff;
        }
        margin-bottom: 40px
    }
    &__boxes{
        display: flex;
        justify-content: space-around;
        &__box{
            // flex-wrap: wrap;
            height: 300px;
            backdrop-filter: blur(3px);
            background-position: center;
            background-size: cover;
            border-radius: 20px;
            border: 2px solid #dfb24f;
            padding: 20px;
            color: #fff;
            font-family: 'Nunito Sans';
            font-size: 24px;
            line-height: 50px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            .center-the-div{
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        &__box1{
            background: linear-gradient(to right, rgba(0,0,0,0.65), rgba(0,0,0,0.65)) ;
            background-position: center;
            background-size: cover;
            flex:1;
            margin-right: 2.5rem;
        }
        &__box2{
            background: linear-gradient(to right, rgba(0,0,0,0.65), rgba(0,0,0,0.65)) ;       
            background-position: center;
            background-size: cover;
            flex: 1;
            margin-right: 2.5rem;
        }
        &__box3{
            background: linear-gradient(to right, rgba(0,0,0,0.65), rgba(0,0,0,0.65)) ;         
            background-position: center;
            background-size: cover;
            flex: 1;
            margin-right: 2.5rem;

        }
        &__box4{
            background: linear-gradient(to right, rgba(0,0,0,0.65), rgba(0,0,0,0.65)) ;      
            background-position: center;
            background-size: cover;
            flex: 1;

        }
        margin-bottom: 80px;
    }
}
@media only screen and (max-width: 1189px) {
    .services{
        padding: 20px;
    }
}
@media only screen and (max-width: 1022px) {
    .services__boxes{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Creates 2 equal-width columns */
        grid-template-rows: auto; /* Automatically adjusts the row height */
        &__box{
            height: 180px;
        }
        &__box4, &__box3, &__box2, &__box1{
            margin: 2.5rem;
        }
    }
    
}
@media only screen and (max-width: 632px) {
    .services{
        padding: 5px;
        margin: 0px;
        &__heading{
            margin: 0;
            
            
        }
        &__boxes{
            grid-template-columns: repeat(2, 1fr); /* Creates 2 equal-width columns */
            &__box{
                font-size: 18px;
                padding: 7px;
            }
            &__box4, &__box3, &__box2, &__box1{
                margin: 0.8rem;
            } 
        }
    }
}
