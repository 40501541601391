.review {
    position: relative;
    margin: 20px 20px 20px 0;
    background: linear-gradient(90deg, #21294f, #000);
    width: 450px;
    height: 180px;
    color: #fff;
    font-family: 'Nunito Sans';
    border-radius: 8px;
    overflow: hidden; /* Prevents extra elements from overflowing */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
    &__content{
        font-size: 14px;
    }
    &__head{
        display: flex;
        align-items: center;
        &__name{
            padding-left: 10px;
            font-size: 11px;
            text-transform: uppercase ;
        }
        img{
            border: 2px solid #fff;
            border-radius: 50%;;
        }
    }
  }
  
  /* Cut bottom-right corner and add folded effect */
  .review::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: #21294f;
    // transform: rotate(45deg);
    transform-origin: bottom right;
    box-shadow: -3px -3px 5px rgba(0, 0, 0, 0.2) inset;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  }
  
  /* To create the cut effect */
  .review::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 40px solid #e8f2fe; /* Same as background color to create a cut */
    border-left: 40px solid transparent;
  }
@media only screen and (max-width: 830px) {
    .review{
        padding: 30px;
        &__content{
            font-size: 13px;
        }
    }
}