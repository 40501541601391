.footer{
    background-color: #21294f;
    &__upper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 5rem;
        &__left{
            display: flex;
            flex-direction: column;
            .brand-contain{
                display: flex; /* Enables flexbox */
                align-items: center;
            }
            .brand-name{
                color: #e9e7e7;
                font-family: 'Great Vibes';
                font-size: 36px;
            }
            &__content{
                color: #fdfdfd;
                font-size: 13px;
                padding-top: 10px;
                line-height: 28px;
                text-transform: uppercase;
                font-weight: 600;
                &__insta{
                    font-style: italic;
                    text-transform: none;
                    font-weight: 300;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }
        }
        &__right{
            color: #e9e7e7;
            text-align: right;
            font-family: 'Nunito Sans';
            font-size: 0.8rem;
            font-weight: 400;
            .footer-content-heading{
                color: #fff;
                font-weight: 800;
            }
        }
    }
    &__lower{
        border-top: 1.5px solid #bdbdbd;
        color: #fff;
        font-family: 'Nunito Sans';
        font-size: 12px;
        padding: 15px;
        font-weight: 700;
        text-align: center;
    }
}
@media only screen and (max-width: 1180px){
    .footer__upper{
        padding: 1rem
    }
} 
@media only screen and (max-width: 767px){
    .footer__upper{
        flex-direction: column;
        &__right{
            text-align: center;
        }
        &__left{
            .brand-contain{
                justify-content: center;
            }
            &__content{
                text-align: center;
                &__insta{
                    display: flex;
                    flex-direction: column-reverse;
                    padding-bottom: 10px;
                }
            }
        }
        
    }
}