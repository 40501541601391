.contact-us{
    background-color: #fff;
    font-family: "Nunito Sans", sans-serif;
    display: flex;
    align-items: center;
    margin: 100px;
    &__left{
        flex:1;
        &__heading{
            font-size: 40px;
            font-weight: 500;
            padding: 8px 0 8px 30px;
            border-left: 5px solid #dfb24f;
        }
        &__content{
            margin-top: 60px;
            &__item{
                padding-bottom: 20px;

                &__heading{
                    display: flex;
                    align-items: center;
                    span{
                        margin-left: 20px;
                        font-size: 22px;
                        font-weight: 600;
                        color: #dfb24f;                            
                    }
                }
                &__text{
                    line-height: 28px;
                    color: #4c4c4c;
                    font-weight: 600;
                    .color-blue{
                        color: #467cbc;
                    }
                    .color-black{
                        color: rgba(0,0,0,0.6);
                    }

                }
            }
        }
    }
    &__right{
        padding: 20px;
        flex:1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 50px;
        height: 470px;
        background: url('../../assets/images/aboutus.jpeg');
        background-position: center;
        background-size: cover;
        border-radius: 24px;
        border: 7px solid #dfb24f;
        &__content{
            a{
                color: #6d201d;
                text-decoration: none;
            }
            text-align: center;
            font-family: 'Alike';
            font-size: 24px;
            background-color: rgb(255, 255, 255,0.5);
            backdrop-filter: blur(3px);
            border-radius: 12px;
            padding: 10px 30px;
            cursor: pointer;
        }
    }
}
@media only screen and (max-width: 1180px){
    .contact-us{
        padding: 2rem;
        margin: 0;

    }
}
@media only screen and (max-width: 828px){
    .contact-us__left__content__item__heading span{
        font-size: 18px;
    }
    .contact-us{
        display: block;
        // flex-direction: column;
        &__left{
            display: flex;
            align-items: center;
            flex-direction: column;
            &__content{
                margin: 30px 0;
                &__item{
                    &__heading{
                        justify-content: center;
                    }
                    &__text{
                        font-size: 13px;
                        line-height: 16px;
                        text-align: center;
                        font-family: 'Nunito sans';
                    }
                }
            }
        }
        &__right{
            margin-left: 0;
            height: 280px;
            &__content{
                font-size: 16px;
            }
        }
    }
}
