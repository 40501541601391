.home{
    position: relative;
    &__bg-text{
        position: absolute;
        top: 29vh;
        width: 90vw;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    width: 100vw;
    overflow: hidden;
}
.home-landing-img{
    background-size: cover;
    background-position: center;
    height: calc(100vh);
    z-index: 1;
    transform-origin: center center;
    opacity: 1;
    background: url('../../assets/images/bg1Compressed.webp');
    background-size: cover;
    background-position: top;
}
.home-bg-outer{
    max-height: 100vh;
}

.home-body{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
    .home-heading{
        display: flex;
        align-items: center;
        padding: 100px 0 40px 0;
        font-family: "Italiana", sans-serif;
        font-size: 26px;
        h2{
            margin-block-start: 0px;
            margin-block-end: 0px;
            padding: 0 12px;
            color: #2d2d74;
            text-align: center;
            line-height: 50px;
            padding: 12px 12px 20px 12px;
            border-radius: 12px;
            background-color: rgba(255,255,255,0.3);
            backdrop-filter: blur(3px);

        }
        &__text{
            text-transform: uppercase;
            font-family: verdana;
            font-weight: 700;
            font-size: 1.5rem;
            color: #d0cbba;
            text-shadow: 1px 1px 1px #000,
                1px 2px 1px #000,
                1px 3px 1px #000,
                1px 4px 1px #000,
                1px 5px 1px #000,
                1px 6px 1px #000,
                1px 7px 1px #000,
                1px 8px 1px #000,
                1px 9px 1px #000,
                1px 10px 1px #000
        }

    }
.about-outer{
    position: relative;
    background-color: #fff;
    z-index: 3;
}
.about{
    width: 100vw;
    margin: 6rem 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    &__details{
        flex:1;
        display: flex;
        flex-direction: column;
        font-family: "Nunito Sans", sans-serif;
        font-size: 18px;
        line-height: 32px;
        &__heading{
            font-family: "Nunito Sans", sans-serif;
            font-size: 45px;
            font-weight: 500;
            border-left: 5px solid #dfb24f;
            padding: 14px 0 14px 30px;
            height: 40px;
            margin-bottom: 40px;
        }
    }
    &__pic{
        flex:1;
        margin-left: 50px;
        background: url('../../assets/images/facility2.jpeg');
        height: 450px;
        background-size: cover;
        background-position: center;
        border-radius: 16px;
        border: 7px solid #dfb24f;

    }
}
.fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
  }

@media only screen and (max-width: 1180px) {
    .about{
        margin: 6rem 2rem;
    }
    .about__pic{
        margin-left: 10px;
    }
    .about__details__heading{
        margin-bottom: 1rem;
    }
}
@media only screen and (max-width: 828px) {
    .home{
        background-color: #000;
    }
    .home-landing-img{
        margin-top: 45px;
        background-image: url('../../assets/images/facility.jpeg');
    }
    .about{
        display: block;
        &__details__text{
            text-align: justify;
            margin-bottom: 40px;
        }
        &__pic{
            margin-left: 0;
            height: 280px;
        }
    }
    .only-web{
        display: none;
    }
}